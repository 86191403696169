<template>
  <div class="flex flex-col">
    <label class="mb-1 flex justify-start">{{ props.labelValue }}</label>
    <select
      :type="type"
      class="border-2 required rounded"
      :value="modelValue"
      @change="onChange($event)"
    >
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  labelValue: {
    type: String,
    required: true,
  },
  modelValue: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
});

const emits = defineEmits(["update:modelValue"]);

function onChange(event: Event) {
  const value = event.target.value;
  emits("update:modelValue", value);
}
</script>
