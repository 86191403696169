<template>
  <TheHeader :title-name="something" />
  <div class="grid grid-cols-3">
    <div class="col-span-1 flex">
      <div class="m-4 space-y-4 flex flex-col">
        <Input
          :model-value="surfaceInput"
          :type-value="inputTypeNumber"
          :label-value="labelSurface"
          :has-icon="true"
          :image-src="firstImage"
          :max-value="1000"
          @update:model-value="surfaceInput = $event"
        />
        <Input
          :model-value="modelCoverageInput"
          :type-value="inputTypeNumber"
          :label-value="labelModelCoverage"
          :has-icon="true"
          :image-src="secondImage"
          :max-value="25"
          @update:model-value="modelCoverageInput = $event"
        />
        <Select
          :label-value="labelSystemType"
          v-model="systemTypeSelected"
          :options="systemTypeSelects"
          @update:model-value="systemTypeSelected = $event"
        />
        <Select
          :label-value="labelModuleType"
          v-model="moduleTypeSelected"
          :options="moduleTypeSelects"
          @update:model-value="moduleTypeSelected = $event"
        />
        <Select
          :label-value="labelGlassType"
          v-model="glassTypeSelected"
          :options="glassTypeSelects"
          @update:model-value="glassTypeSelected = $event"
        />
        <Select
          :label-value="labelColourType"
          v-model="colourTypeSelected"
          :options="colourTypeSelects"
          @update:model-value="colourTypeSelected = $event"
        />
        <label>
          <input
            type="radio"
            :value="true"
            v-model="creaAdapto"
            @click="toggleRadio('creaAdapto', true)"
          />
          Crea/Adapto
        </label>
        <label>
          <input
            type="radio"
            :value="true"
            v-model="aluSubConstruction"
            @click="toggleRadio('aluSubConstruction', true)"
          />
          ALU sub-construction
        </label>

        <button
          class="bg-red-700 rounded-md max-w-32 text-white"
          @click="callAPI"
        >
          Calculate
        </button>
      </div>
    </div>
    <div class="col-span-2">
      <div v-if="isCalled" class="m-4 mb-10">
        <h1 class="text-left text-xl mb-4">
          {{ displayedSystemTypeSelected }} -
          {{ displayedModuleTypeSelected }} - {{ displayedGlassTypeSelected }} -
          {{ displayedColourTypeSelected }}
        </h1>
        <div v-if="apiResponse" class="space-y-4">
          <p>
            PV system active area = {{ displayedSurfaceInput }} m<sup>2</sup>
          </p>
          <p>
            Standard module coverage area = {{ roundedActiveArea }} m<sup
              >2</sup
            >
          </p>
          <p v-if="displayedSystemTypeSelected === 'Roof'">
            Crea module area = {{ roundedCreaModuleArea }} m<sup>2</sup>
          </p>
          <p v-else-if="displayedSystemTypeSelected === 'Facade'">
            Adapto module area = {{ roundedCreaModuleArea }} m<sup>2</sup>
          </p>
          <p>
            Estimated quotation price (net price with all standard components):
            <b>{{ roundedQuotationPrice }} CHF</b> (+/-
            {{ quotationPriceTolerance }} CHF)
          </p>
          <p>
            Estimated kWp: <b>{{ apiResponse.estimated_kwp }} kWp</b>
          </p>
          <p>Price per m<sup>2</sup>: ~ {{ areaPrice }} CHF/m<sup>2</sup></p>
          <p>Price per kWp: ~ {{ kwpPrice }} CHF/kWp</p>
          <p class="text-sm">
            <i>
              Please note, if you make short changes, you must click the
              "Calculate" button again!
            </i>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheHeader from "@/components/TheHeader.vue";
import Input from "@/components/Form/Input.vue";
import Select from "@/components/Form/Select.vue";
import { ref } from "vue";
import axios from "axios";
import firstImage from "@/assets/images/surface-image.png";
import secondImage from "@/assets/images/module-coverage-image.png";

const token = process.env.VUE_APP_AUTH_TOKEN;
const apiUrl = process.env.VUE_APP_API_URL;
const something = "3S Price Indication Tool";
const isCalled = ref(false);
const apiResponse = ref(null);

// type of the input field
const inputTypeNumber = "number";

const labelSurface = "Roof/Facade Active Surface Area [m²] (max. 1000m²)";
const surfaceInput = ref();

const labelModelCoverage =
  "Module coverage loss factor (Crea/Adapto area) [%] (max. 25%)";
const modelCoverageInput = ref();

const labelSystemType = "System Type";
const systemTypeSelects = ref([
  { text: "Roof", value: "Roof" },
  { text: "Facade", value: "Facade" },
]);
const systemTypeSelected = ref();

const labelModuleType = "Module Type";
const moduleTypeSelects = ref([
  { text: "MegaSlate", value: "MegaSlate" },
  { text: "TeraSlate", value: "TeraSlate" },
]);
const moduleTypeSelected = ref();

const labelGlassType = "Glass Type";
const glassTypeSelects = ref([
  { text: "Standard", value: "Standard" },
  { text: "Satinato", value: "Satinato" },
]);
const glassTypeSelected = ref();

const labelColourType = "Colour Type";
const colourTypeSelects = ref([
  { text: "Black", value: "Black" },
  { text: "Flair (SX/DCP)", value: "Flair (SX/DCP)" },
]);
const colourTypeSelected = ref();

const creaAdapto = ref(false);
const aluSubConstruction = ref(false);
const roundedQuotationPrice = ref();
const quotationPriceTolerance = ref();
const roundedActiveArea = ref();
const roundedCreaModuleArea = ref();
const areaPrice = ref();
const kwpPrice = ref();

const displayedSurfaceInput = ref();
const displayedSystemTypeSelected = ref();
const displayedModuleTypeSelected = ref();
const displayedGlassTypeSelected = ref();
const displayedColourTypeSelected = ref();

const toggleRadio = (fieldName, currentValue) => {
  if (fieldName === "creaAdapto") {
    creaAdapto.value = creaAdapto.value === currentValue ? false : currentValue;
  } else if (fieldName === "aluSubConstruction") {
    aluSubConstruction.value =
      aluSubConstruction.value === currentValue ? false : currentValue;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const callAPI = async (async) => {
  try {
    displayedSurfaceInput.value = surfaceInput.value;
    displayedSystemTypeSelected.value = systemTypeSelected.value;
    displayedModuleTypeSelected.value = moduleTypeSelected.value;
    displayedGlassTypeSelected.value = glassTypeSelected.value;
    displayedColourTypeSelected.value = colourTypeSelected.value;
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        system_type: systemTypeSelected.value,
        module_type: moduleTypeSelected.value,
        glass_type: glassTypeSelected.value,
        colour_type: colourTypeSelected.value,
        crea_bool: creaAdapto.value,
        alu_bool: aluSubConstruction.value,
        roof_area: surfaceInput.value,
        coverage_factor: modelCoverageInput.value,
      },
    });
    apiResponse.value = response.data;

    roundedQuotationPrice.value =
      Math.ceil(apiResponse.value.quotation_price / 100) * 100;

    quotationPriceTolerance.value = Math.round(
      roundedQuotationPrice.value / 10
    );

    roundedActiveArea.value = Math.round(apiResponse.value.area);

    roundedCreaModuleArea.value = Math.round(
      apiResponse.value.crea_module_area
    );

    areaPrice.value = Math.round(
      roundedQuotationPrice.value / surfaceInput.value
    );

    kwpPrice.value = Math.round(
      roundedQuotationPrice.value / apiResponse.value.estimated_kwp
    );

    isCalled.value = true;
    // surfaceInput.value = null;
    // modelCoverageInput.value = null;
    // systemTypeSelected.value = null;
    // moduleTypeSelected.value = null;
    // glassTypeSelected.value = null;
    // colourTypeSelected.value = null;
  } catch (error) {
    if (error.response) {
      // Der Server antwortete mit einem Status außerhalb des Bereichs von 2xx
      console.log("Error 1" + error.response.data);
      console.log("Error 2" + error.response.status);
      console.log("Error 3" + error.response.headers);
    } else if (error.request) {
      // Die Anfrage wurde gemacht, aber keine Antwort wurde empfangen
      console.log("Error 4" + error.request);
    } else {
      // Ein Fehler wurde beim Setup der Anfrage ausgelöst
      console.log("Error", error.message);
    }
  }
};
</script>
