<template>
  <div class="flex flex-col">
    <div class="flex items-center mb-1">
      <label class="mb-1 flex-grow">{{ props.labelValue }}</label>
      <div
        v-if="props.hasIcon"
        @mouseover="showImage = true"
        @mouseout="showImage = false"
        class="flex-none relative"
      >
        <Icons name="info-logo" />
        <img
          v-if="showImage"
          :src="imageSrc"
          class="absolute z-50"
          style="
            width: 800px;
            height: auto;
            min-width: 800px;
            min-height: 400px;
          "
        />
      </div>
    </div>
    <input
      :type="props.typeValue"
      class="border-2 required rounded text-black"
      v-model.number="displayModelValue"
      @input="handleInput($event)"
    />
  </div>
</template>

<script setup lang="ts">
import Icons from "@/components/Icons";
import { defineProps, ref, watchEffect, defineEmits } from "vue";

const props = defineProps({
  labelValue: {
    type: String,
    required: true,
  },
  typeValue: {
    type: String,
    required: false,
  },
  modelValue: {
    type: [Number, String],
    required: false,
  },
  hasIcon: {
    type: Boolean,
    required: false,
  },
  imageSrc: {
    type: String,
    required: false,
  },
  maxValue: {
    type: Number,
    required: false,
  },
});

const showImage = ref(false);

const displayModelValue = ref(props.modelValue);

const emit = defineEmits(["update:modelValue"]);

watchEffect(() => {
  if (
    props.maxValue !== undefined &&
    displayModelValue.value > props.maxValue
  ) {
    displayModelValue.value = props.maxValue;
    emit("update:modelValue", props.maxValue);
  } else {
    emit("update:modelValue", displayModelValue.value);
  }
});

const handleInput = (event) => {
  const inputValue = event.target.value;

  if (props.typeValue === "number") {
    let value = parseFloat(inputValue);
    if (isNaN(value)) {
      value = 0;
    }
    if (props.maxValue !== undefined && value > props.maxValue) {
      value = props.maxValue;
    }
    emit("update:modelValue", value);
  } else {
    emit("update:modelValue", inputValue);
  }
};
</script>
