<template>
  <TheHeader />
  <div class="grid grid-cols-1 place-items-center h-56">
    <div class="bg-red-700 w-96 h-66 rounded-md mt-16 px-6 py-3 text-white">
      <div class="mb-6">
        <Input
          :model-value="usernameInput"
          :label-value="labelUsername"
          @update:model-value="usernameInput = $event"
        />
      </div>
      <div>
        <Input
          :model-value="passwordInput"
          :type-value="inputTypePassword"
          :label-value="labelPassword"
          @update:model-value="passwordInput = $event"
        />
      </div>
      <div class="grid grid-cols-1 place-items-center mt-12">
        <button class="bg-white rounded-md w-32 text-red-700" @click="logInto">
          Login
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import TheHeader from "@/components/TheHeader.vue";
import Input from "@/components/Form/Input.vue";
import router from "@/router/index";

// label for the input fields
const labelUsername = "Benutzername";
const labelPassword = "Passwort";

// type of the input field
const inputTypePassword = "password";

const usernameInput = ref("");
const passwordInput = ref();

const logInto = () => {
  const username = process.env.VUE_APP_USER_NAME;
  const password = process.env.VUE_APP_PASS_WORD;
  if (usernameInput.value === username && passwordInput.value === password) {
    // Speichern des Authentifizierungsstatus im localStorage
    localStorage.setItem("isAuthenticated", "true");
    // Weiterleitung zur Home-Seite
    router.push("/home");
  }
};
</script>
