import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// In Ihrer router/index.ts oder da, wo Sie Ihren Router definieren

router.beforeEach((to, from, next) => {
  // Nehmen wir an, Sie speichern den Auth-Status im LocalStorage
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  // Prüfen, ob die Route, auf die zugegriffen wird, eine Authentifizierung erfordert
  // und der Benutzer nicht authentifiziert ist. Hier nehmen wir an, dass nur /home Authentifizierung erfordert.
  // Sie können Ihre Logik anpassen, um dies dynamischer zu gestalten, z.B. mit Route-Meta-Feldern.
  if (to.name !== "login" && !isAuthenticated) {
    // Benutzer nicht authentifiziert, umleiten zur Login-Seite
    next({ name: "login" });
  } else {
    // Wenn der Benutzer authentifiziert ist oder die Route die Login-Seite ist, fortfahren
    next();
  }
});

export default router;
